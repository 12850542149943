<template>
  <div ref="containerRef" >

    <div v-if="element.config.style === 'rows'">
      <div v-for="(item , i) in element.config.elements" :key="i">

        <v-sheet :height="element.config.gutter_vertical / (i === 0 ? 2 : 1)" color="transparent"
                 class="d-flex "
                 :class="[{'align-center' : i > 0}]"
        >
          <v-divider
              v-if="element.config.thickness !== -1"
              style="width: 100%;"
              :style="lineStyle"
          />
        </v-sheet>
        <v-row
            :style="getRowStyle"
            no-gutters>
          <v-col
              :style="getColStyle(i)"
              :cols="leftRowColumns"
          >
            <h4 :key="SCALE_COEFFICIENT + 'rows_title'"
                :style="BLOCK_TEXT_CSS(element , blockStyle , 'list' , 'title' , null , null , DEFAULT_BLOCK_WIDTH)"
                v-html="parseNewLine(item.title)"
            />
          </v-col>
          <v-col
              :style="getColStyle(i)"
              :cols="rightRowColumns"
          >
            <h5 :key="SCALE_COEFFICIENT + 'rows_text'"
                :style="BLOCK_TEXT_CSS(element , blockStyle , 'list' , 'text' , null , null , DEFAULT_BLOCK_WIDTH)"
                v-html="parseNewLine(item.text)"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-if="element.config.style === 'grid_numbers'">
      <div >
        <v-row no-gutters :style="getRowStyle">
          <v-col v-for="(item , i) in element.config.elements" :key="i"
                 :cols="gridColumns"
                 :style="getColStyle(i)"
          >
            <h4 :key="SCALE_COEFFICIENT + 'grid_numbers_number'"
                :style="BLOCK_TEXT_CSS(element , blockStyle , 'list' , 'number', null , null , DEFAULT_BLOCK_WIDTH)"
                :class="[{'text-center' : element.config.content_align_horizontal === 'center' },
                         {'text-right' : element.config.content_align_horizontal === 'end' }
                ]"
            > {{ i + 1 }}</h4>
            <v-sheet color="transparent" :height="element.config.image_margin || 4"
                     class="d-flex align-center"
            >
              <v-divider
                  v-if="element.config.thickness !== -1"
                  :style="lineStyle"
              />
            </v-sheet>

            <h4 :key="SCALE_COEFFICIENT + 'grid_numbers_title'"
                :style="BLOCK_TEXT_CSS(element , blockStyle , 'list' , 'title', null , null , DEFAULT_BLOCK_WIDTH)"
                :class="[{'text-center' : element.config.content_align_horizontal === 'center' },
                         {'text-right' : element.config.content_align_horizontal === 'end' }
                  ]"
                v-html="parseNewLine(item.title)" />
            <h5 :key="SCALE_COEFFICIENT + 'grid_numbers_text'"
                :style="BLOCK_TEXT_CSS(element , blockStyle , 'list' , 'text' , null , null , DEFAULT_BLOCK_WIDTH) +
                        `margin-top : ${item.title ? (element.config.heading_margin || 0) : 0}px`
                "
                :class="[{'text-center' : element.config.content_align_horizontal === 'center' },
                          {'text-right' : element.config.content_align_horizontal === 'end' }
                ]"
                v-html="parseNewLine(item.text)"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-if="element.config.style === 'grid_icons'">
      <div >
        <v-row no-gutters :style="getRowStyle" >
          <v-col v-for="(item , i) in element.config.elements" :key="i"
                 :cols="gridColumns"
                 :style="getColStyle(i)"
          >
            <v-sheet color="transparent" :height="element.config.gutter_vertical / (i >= element.config.grid_columns ? 1 : 2)"
                     class="d-flex"
                     :class="[{'align-center' : i >= element.config.grid_columns}]"
            >
              <v-divider
                  v-if="element.config.thickness !== -1"
                  :style="lineStyle"
              />
            </v-sheet>

            <div class="d-flex" :class="[{'align-center' : element.config.grid_columns === 1}]">
              <ws-img
                  v-if="!IS_WESTUDY_ICON(item.img) && item.img"
                  :width="imageSize"
                  :height="imageSize"
                  contain
                  :src="item.img"
                  class="mr-4"
                  :style="`margin-right : ${element.config.image_margin || 16}px !important`"
              />
              <v-sheet
                    v-else-if="item.img"
                    :src="item.img"
                    :style="`
                    background-color: ${BLOCK_COLOR_PARAM(element , 'image_icon_color' , blockStyle) || 'black'} !important;
                    -webkit-mask: url('${item.img}') no-repeat ;
                    mask: url('${item.img}') no-repeat 50% 50%;
                    mask-size : contain;
                    position:relative;
                    margin-right : ${element.config.image_margin || 16}px !important;
                    `
              "
                    :height="imageSize"
                    :width="imageSize"
                    :min-height="imageSize"
                    :min-width="imageSize"
                    :max-height="imageSize"
                    :max-width="imageSize"
                    contain
                    class="mr-4"
                    :key="item.img"
                />
              <v-sheet color="transparent"  style="width: 100%">
                <h4 :key="SCALE_COEFFICIENT + 'grid_icons_title'"
                    :style="BLOCK_TEXT_CSS(element , blockStyle , 'list' , 'title', null , null , DEFAULT_BLOCK_WIDTH)"
                    style="width: 100%"
                    v-html="parseNewLine(item.title)"
                />
                <h5 :key="SCALE_COEFFICIENT + 'grid_icons_text'"
                    :style="BLOCK_TEXT_CSS(element , blockStyle , 'list' , 'text', null , null , DEFAULT_BLOCK_WIDTH) +
                            `margin-top : ${item.title ? (element.config.heading_margin || 0) : 0}px`
                    "
                    class="font-weight-regular"
                    v-html="parseNewLine(item.text)" />
              </v-sheet>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-if="element.config.style === 'grid_icons_top'">
      <div >
        <v-row no-gutters :style="getRowStyle" >
          <v-col v-for="(item , i) in element.config.elements" :key="i"
                 :cols="gridColumns"
                 :style="getColStyle(i)"
          >

            <div class="d-flex "
                 :class="[{'justify-center' : element.config.content_align_horizontal === 'center' },
                          {'justify-end' : element.config.content_align_horizontal === 'end' }
                 ]"
            >
              <ws-img
                  v-if="!IS_WESTUDY_ICON(item.img) && item.img"
                  :width="imageSize"
                  :height="imageSize"
                  contain
                  :src="item.img"
                  class="mr-4"
                  :style="`margin-right : ${element.config.image_margin || 16}px !important`"
              />
              <v-sheet
                  v-else-if="item.img"
                  :src="item.img"
                  :style="`
                    background-color: ${BLOCK_COLOR_PARAM(element , 'image_icon_color' , blockStyle) || 'black'} !important;
                    -webkit-mask: url('${item.img}') no-repeat ;
                    mask: url('${item.img}') no-repeat 50% 50%;
                    mask-size : contain;
                    position:relative;
                    margin-right : ${element.config.image_margin || 16}px !important;
                    `
              "
                  :height="imageSize"
                  :width="imageSize"
                  :min-height="imageSize"
                  :min-width="imageSize"
                  :max-height="imageSize"
                  :max-width="imageSize"
                  contain
                  class="mr-4"
                  :key="item.img"
              />
            </div>


            <v-sheet color="transparent" :height="element.config.image_margin"
                     class="d-flex align-center"
            >
              <v-divider
                  v-if="element.config.thickness !== -1"
                  :style="lineStyle"
              />
            </v-sheet>

            <v-sheet color="transparent"  style="width: 100%">
              <h4 :key="SCALE_COEFFICIENT + 'grid_icons_top_title'"
                  :style="BLOCK_TEXT_CSS(element , blockStyle , 'list' , 'title' , null , null , DEFAULT_BLOCK_WIDTH)"
                  style="width: 100%"
                  v-html="parseNewLine(item.title)"
                  :class="[{'text-center' : element.config.content_align_horizontal === 'center' },
                          {'text-right' : element.config.content_align_horizontal === 'end' }
                 ]"
              />
              <h5 :key="SCALE_COEFFICIENT + 'grid_icons_top_text'"
                  :style="BLOCK_TEXT_CSS(element , blockStyle , 'list' , 'text' , null , null , DEFAULT_BLOCK_WIDTH) +
                           `margin-top : ${item.title ? (element.config.heading_margin || 0) : 0}px`
                  "
                  :class="[{'text-center' : element.config.content_align_horizontal === 'center' },
                          {'text-right' : element.config.content_align_horizontal === 'end' }
                  ]"
                  class="font-weight-regular" v-html="parseNewLine(item.text)"
              />
            </v-sheet>

          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "elementListEditor",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String
    },
    block : {
      type : Object,
      default() { return { config : {}} }
    }
  },
  data() {
    return {
      element : { config : {}},
      height : 0,
      firstLoad : true,
    }
  },
  computed : {
    imageSize() {
      return (this.element.config.image_size || 100) * this.SCALE_COEFFICIENT
    },

    siteConfig() {
      return this.$store.state.avalon.config
    },
    domElement() {
      return this.$refs.containerRef
    },
    lineStyle() {
      let style = ''
      if ( this.element.config.thickness  ) {
        style +=  `border-width : ${this.element.config.thickness}px; `
      }
      style += `border-color : ${this.CONFIG_PARAM_COLOR('styles',this.blockStyle,'list_line')}`

      return style
    } ,
    getRowStyle() {
      let style = ''
      let paddingValue = this.element.config.gutter || 0
      if ( paddingValue && !( this.SM || this.MOBILE_VIEW ) ) {
        style += `margin-left : -${paddingValue/2}px;`
        style += `margin-right : -${paddingValue/2}px;`
      }
      return style
    },
    leftRowColumns() {
      if ( this.MOBILE_VIEW ) {
        return 12
      }
      return this.element.config.list_proportion === '2-3' ? 4 : 6
    },
    rightRowColumns() {
      if ( this.MOBILE_VIEW ) {
        return 12
      }
      return this.element.config.list_proportion === '2-3' ? 8 : 6
    },
    gridColumns() {
      if ( this.MOBILE_VIEW ) {
        return 12
      }
      if (this.element.config.style === 'grid_icons' &&  this.element.config.grid_columns > 2 ) {
        return 6
      }

      return this.gridRowColumnsNumber

    },
    gridIconsRows() {
      switch (this.element.config.grid_icons_rows) {
        case 2 : return 6
        default : return 12
      }
    },
    gridRowColumnsNumber() {
      switch (this.element.config.grid_columns) {
        case 1 : return 12
        case 2 : return 6
        case 3 : return 4
        case 4 : return 3
        default : return 6
      }
    },
    elementStyle() {

      let style = ''
      if ( this.element.config.thickness ) {
        style +=  `border-width: ${this.element.config.thickness || 1}px;`
      }


      if ( this.element.config.background_color ) {
       style += `border-color : ${this.GET_AVALON_COLOR(this.element.config.background_color) } ;`
      }


      if ( this.blockStyle ) {
        style += `border-color : ${this.CONFIG_PARAM_COLOR(
            'styles' ,
            this.blockStyle ,
            'line_color'
        ) } ;`

      } else {
        style += `border-color : ${this.GET_AVALON_COLOR(this.element.config.background_color) } ;`
      }


      return style

    },
    CELLS_WIDTH() {
      return this.element[this.DIM_KEY['x2']] - this.element[this.DIM_KEY['x1']] +1
    },
  },
  watch : {
    height(value) {
      this.updateHeight()
      if ( value > 0 ) {
        setTimeout(()=> {
          this.getHeight()
          this.firstLoad =  false
        } , 400)
      }
      setTimeout(  this.updateHeight , 300 )
    },
    'siteConfig.fonts.elements' : {
      handler() {
        setTimeout(   this.getHeight , 300)
      },
      deep : true
    },
    'element.config' : {
      handler(value , old) {
        if ( this.EQ(value , old) ) {
          return
        } else {
          this.getHeight()
          setTimeout(() => {
            this.getHeight()
          },10)
        }
      },
      deep : true
    },

    value : {
      handler() {

        if ( JSON.parse(JSON.stringify(this.value)) !== JSON.parse(JSON.stringify(this.element)) ) {
          this.element = JSON.parse(JSON.stringify(this.value))
        }
      },
      deep : true
    },
    CELLS_WIDTH() {
      setTimeout(this.getHeight , 30)
    }
  },
  methods : {
    ...mapActions('avalonPage', ['EDIT_ELEMENT']),

    updateHeight() {
      this.$emit('actual-height-update' , this.height)
      if ( this.firstLoad ) {
        return
      }
      this.$emit('update-height', this.height )
    },

    getHeight() {
      let height = this.domElement.clientHeight
      this.height = height
    },

    getColStyle(index) {
      let style = ''
      let paddingValue = this.element.config.gutter || 0
      if ( paddingValue && !(this.SM || this.MOBILE_VIEW) ) {
        style += `padding-left : ${paddingValue/2}px;`
        style += `padding-right : ${paddingValue/2}px; `
      }
      if ( ((this.SM || this.MOBILE_VIEW) && index > 0 ) || (this.element.config.style === 'grid_numbers'  && index >= this.element.config.grid_columns && this.element.config.gutter_vertical) ) {
        style += `padding-top : ${this.element.config.gutter_vertical}px;`
      }

      return style
    },

    parseNewLine(input) {
      if ( !input ) {
        return ''
      }
      return input.replace(/\n/g,'<br>')
    }
  },
  mounted(){
    if ( this.value ) {
      this.element = JSON.parse(JSON.stringify(this.value))
      this.$emit('input',this.element)
    }
    setTimeout(() => {
      this.getHeight()
      this.updateHeight()
    } , 500)
  }
}
</script>

<style scoped>

</style>